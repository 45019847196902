import * as React from "react"

const Icon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="210mm"
    height="210mm"
    viewBox="0 0 210 210"
    {...props}
  >
    <g transform="translate(0 -87)">
      <path
        style={{
          fill: "#5e5e5e",
          fillOpacity: 1,
          strokeWidth: 0.25764495,
        }}
        d="M97.615 89.48c-40.698 2.562-78.163 31.397-90.58 70.307-14.387 40.938.902 89.915 36.103 115.313 34.576 26.784 86.435 27.385 121.607 1.376 36.631-25.133 52.866-75.508 37.754-117.295-13.098-40.576-53.699-69.878-96.294-69.889-2.86-.154-5.729.154-8.59.188z"
      />
      <path
        style={{
          fill: "#313131",
          fillOpacity: 1,
          strokeWidth: 0.25764495,
        }}
        d="M100.648 95.258c-46.591 1.345-88.974 40.809-92.625 87.584-4.629 43.666 23.87 89.087 66.336 102.025 38.715 12.918 85.263-1.045 109.04-34.897 24.151-33.055 25.311-81.596.25-114.529-19.01-26.02-51.14-41.85-83-40.183Z"
      />
      <path
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: 0.85841483,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M103.092 178.735v110.738l5.697-11.095s5.664-12.188 5.9-30.306c-3.56-19.571-5.463-45.065-3.448-69.34M94.94 178.728c2.016 24.277.114 49.772-3.447 69.343.236 18.119 5.9 30.306 5.9 30.306l5.697 11.095V178.735"
      />
      <circle
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: 0.85841483,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        cx={103.637}
        cy={124.669}
        r={6.95}
      />
      <path
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: 0.85841483,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M98.233 171.17s-.836-9.436-1.196-16.224-2.954-18.696 5.33-23.48M108.952 171.17s.836-9.436 1.196-16.224 2.954-18.696-5.33-23.48"
      />
      <path
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: 0.9310559,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M103.588 117.782V95.057"
      />
      <path
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: 0.85841483,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M111.937 171.167H94.11m.76 7.564h16.37m-38.3-13.39c-3.046 0-5.515 3.532-5.515 7.89 0 4.357 2.47 7.89 5.515 7.89 2.266-.003 4.144-1.99 4.971-5.008l16.96 2.617-.76-7.564-16.017-.06c-.666-3.408-2.68-5.765-5.154-5.766zm60.323 0c-2.474 0-4.488 2.357-5.154 5.766l-16.016.06-.853 7.564 17.052-2.617c.828 3.018 2.705 5.004 4.97 5.007 3.046 0 5.515-3.532 5.516-7.89 0-4.357-2.47-7.89-5.515-7.89z"
      />
      <path
        style={{
          fill: "#fc0",
          stroke: "#000",
          strokeWidth: 0.30657673,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M71.857 180.464c-.957-.328-1.759-.963-2.414-1.914-1.055-1.53-1.525-3.19-1.52-5.366.002-1.383.143-2.227.573-3.43 1.308-3.659 4.523-5.053 6.857-2.973 1.064.949 1.93 2.6 2.258 4.31l.087.452 7.99.055 7.99.055.04.304c.083.62.634 6.188.614 6.205-.012.01-1.05-.142-2.307-.338-5.863-.913-14.285-2.207-14.37-2.207-.053 0-.165.25-.26.58-.626 2.166-1.964 3.827-3.452 4.286-.656.203-1.459.195-2.086-.02zM132.214 180.475c-.566-.195-1.346-.712-1.74-1.156-.694-.779-1.294-1.894-1.653-3.072-.106-.346-.232-.63-.28-.629-.049 0-3.838.578-8.42 1.282-4.584.705-8.338 1.277-8.343 1.272-.005-.006.152-1.421.349-3.145.196-1.723.358-3.187.359-3.253.003-.109.703-.124 7.984-.174 4.39-.03 7.99-.061 8-.07.009-.007.113-.395.23-.859.76-3.008 2.507-4.861 4.583-4.861 1.008 0 1.9.422 2.802 1.326 1.352 1.355 2.095 3.26 2.19 5.614.069 1.713-.178 3.13-.783 4.502-.741 1.68-2.1 3-3.398 3.3-.55.126-1.387.092-1.88-.077z"
      />
      <path
        style={{
          fill: "#fc0",
          fillOpacity: 1,
          stroke: "#000",
          strokeWidth: 0.30657673,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M95.274 178.032c-.04-.24-.631-6.16-.631-6.314 0-.06 2.261-.083 8.465-.083h8.466l-.372 3.318-.373 3.319H95.313z"
      />
      <path
        style={{
          fill: "#4d4d4d",
          stroke: "#000",
          strokeWidth: 0.86712992,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M95.51 179.335c1.541 20.578.627 41.324-2.34 61.736-.585 3.698-1.401 7.41-.964 11.177.526 8.106 1.962 16.234 4.897 23.83 1.604 3.702 3.468 7.395 5.392 10.878.084-35.878.066-71.78.035-107.644-2.336.015-4.7-.03-7.02.023z"
      />
      <path
        style={{
          fill: "#4d4d4d",
          stroke: "#000",
          strokeWidth: 0.71092349,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="m103.552 179.333.007 107.936c2.889-5.443 5.992-11.008 7.489-17.087 1.901-7.11 3.01-14.47 3.043-21.834-.883-6.492-2.03-12.956-2.622-19.49a267.201 267.201 0 0 1-1.44-34.453c.09-5.032.183-10.1.676-15.093-2.381.014-4.786-.027-7.153.021z"
      />
      <path
        style={{
          fill: "#000",
          stroke: "#000",
          strokeWidth: 0.01916104,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M103.518 287.354c-.022.022-.008.059-.013.087 0 .082-.003.165.003.247.01.03.046.008.048-.016.044-.087.091-.173.132-.26.005-.034-.042-.028-.063-.038-.034-.006-.07-.025-.105-.02z"
      />
      <path
        style={{
          fill: "#9d4100",
          fillOpacity: 1,
          stroke: "#000",
          strokeWidth: 0.43356496,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M102.323 132.028c-2.633 1.67-4.397 4.499-4.972 7.542-1.081 5.506-.058 11.11.22 16.64.332 4.818.626 9.64 1.107 14.446 3.256.03 6.558.029 9.815 0 .47-4.243.694-8.513 1.011-12.771.206-4.812.952-9.602.808-14.426-.142-3.222-.83-6.612-2.913-9.17-.771-.84-1.594-1.746-2.62-2.235-.818-.007-1.638-.025-2.456-.026z"
      />
      <path
        style={{
          fill: "#ffd42a",
          stroke: "#000",
          strokeWidth: 0.43356496,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M103.368 118.187c-2.714.087-5.198 2.152-5.959 4.727-.557 2.151-.07 4.625 1.589 6.18 1.748 2.002 4.81 2.608 7.21 1.497 2.467-1.054 4.159-3.739 3.855-6.434-.116-2.48-1.954-4.679-4.214-5.575a6.668 6.668 0 0 0-2.481-.395zM96.006 179.794c.788 10.126.719 20.296.487 30.443a295.38 295.38 0 0 1-3.552 35.285c-.725 4.423-.127 8.907.391 13.316.81 6.03 2.168 12.028 4.492 17.664 1.242 2.883 2.71 5.661 4.106 8.472.101-2.166.034-4.466.07-6.684.128-29.242.137-58.485.071-87.728-.03-3.594-.03-7.21-.105-10.79-1.983.015-3.992-.03-5.96.022z"
      />
      <path
        style={{
          fill: "#4b4b8a",
          fillOpacity: 1,
          stroke: "#000",
          strokeWidth: 0.43388969,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M107.11 179.77c-1.02-.004-2.036-.001-3.044.023.013 35.106-.031 70.213.04 105.32.509-.676.865-1.611 1.305-2.386 2.232-4.23 4.215-8.629 5.34-13.295 1.68-6.528 2.743-13.243 2.845-19.99-.182-3.676-.945-7.294-1.397-10.942a260.59 260.59 0 0 1-2.496-27.566c-.173-7.66-.159-15.325.01-22.984.064-2.727.283-5.472.451-8.178-1.015.007-2.035 0-3.054-.003zm-5.455.21-2.564.002c-.894.04-1.84-.06-2.706.107-.113 1.63.182 3.273.208 4.908.337 7.51.206 15.033.183 22.548-.053 6.245-.582 12.475-1.12 18.694-.597 6.634-1.539 13.23-2.513 19.817-.67 5.152.114 10.342.86 15.44 1.05 6.514 2.831 12.968 5.909 18.832.588 1.141 1.12 2.404 1.745 3.47.173-12.595.093-25.192.131-37.787 0-21.404.046-42.808-.044-64.211-.037-.603-.019-1.231-.089-1.82z"
      />
      <path
        style={{
          fill: "#ffda4d",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.43356496,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M101.511 172.234c-2.059.027-4.126-.026-6.175.069.115 1.789.307 3.554.513 5.334 4.816.091 9.632.035 14.448 0 .194-1.802.434-3.6.594-5.405-3.127 0-6.253 0-9.38.002z"
      />
      <path
        style={{
          fill: "#8c8ca4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.61315346,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M97.333 180.225c-.592.008-.991.261-.719.924.494 8.363.43 16.752.418 25.128-.076 11.314-1.246 22.594-2.746 33.8-.779 4.772-1.409 9.628-.894 14.466.855 8.127 2.321 16.32 5.865 23.75.655 1.38 1.302 2.85 2.023 4.14.14-1.18.091-2.4-.564-3.43-4.46-9.247-6.575-19.54-6.741-29.776.057-4.623 1.157-9.15 1.623-13.738 2.339-18.04 2.795-36.29 1.949-54.45-.111-.157.099-.831-.214-.814zM108.562 180.074c-.584 7.17-.53 14.376-.54 21.565a284.916 284.916 0 0 0 3.416 41.424c1.101 8.372.178 16.944-2.052 25.06-1.193 4.117-2.667 8.18-4.623 11.998-.582.54-.239 1.487-.345 2.197.012.739-.142 2.472.442.877 2.465-4.745 4.713-9.65 5.873-14.896 1.767-7.225 2.922-14.71 2.31-22.159-1.78-11.449-3.174-22.975-3.555-34.564-.284-9.626-.186-19.265.21-28.886.03-.878.148-1.774.191-2.635-.438.015-.9-.028-1.327.019z"
      />
      <path
        style={{
          fill: "#ffd42a",
          stroke: "none",
          strokeWidth: 0.43356496,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M103.434 119.748c-5.844.023-6.161 9.329-.394 9.792 5.98 1.007 7.587-8.65 1.62-9.68a5.231 5.231 0 0 0-1.226-.112z"
      />
      <path
        style={{
          fill: "#ffda4d",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.19908331,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M72.317 177.886c-.62-.213-1.141-.625-1.567-1.243-.685-.994-.99-2.071-.988-3.485.002-.898.094-1.445.373-2.227.85-2.376 2.937-3.28 4.453-1.93.69.616 1.253 1.688 1.466 2.799l.057.293 5.188.036 12.306.192.026.197c.054.403.411 4.018.398 4.03-.007.006-.681-.092-1.497-.22-3.807-.593-16.394-1.59-16.449-1.59-.034 0-.107.162-.17.377-.406 1.407-1.274 2.485-2.24 2.784a2.307 2.307 0 0 1-1.356-.013zM133.916 178.234c.622-.213 1.142-.626 1.568-1.243.685-.994.99-2.072.987-3.485-.002-.898-.093-1.445-.372-2.227-.85-2.376-2.938-3.281-4.453-1.93-.691.616-1.253 1.688-1.466 2.799l-.057.293-5.188.036-12.306.192-.027.197c-.054.403-.41 4.018-.398 4.03a44.2 44.2 0 0 0 1.498-.22c3.807-.593 16.394-1.59 16.449-1.59.034 0 .107.162.17.377.406 1.407 1.274 2.485 2.24 2.783.427.132.948.127 1.355-.012z"
      />
      <path
        style={{
          fill: "#853500",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.15765998,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M102.38 132.232c-2.01 1.38-3.57 3.405-4.352 5.718-.766 2.288-.91 4.729-.943 7.125.047 3.95.542 7.876.758 11.818.292 4.514.616 9.027 1.03 13.531.116-.012.396.1.468-.056-.37-4.044-.605-8.1-.894-12.151-.262-3.31-.706-6.7.062-9.974.424-1.825 1.326-3.588 2.813-4.768.557-.442 1.158-.983 1.916-.937.508-.018 1.065-.047 1.481.3 1.12.72 2.084 1.69 2.7 2.877 1.354 2.478 1.478 5.393 1.345 8.152-.142 2.6-.42 5.191-.553 7.792a492.98 492.98 0 0 1-.65 8.592c0 .314.422.168.617.18.241-.113.12-.476.19-.684.495-5.082.773-10.181 1.136-15.273.281-3.873.763-7.753.539-11.642-.195-2.91-.874-5.926-2.717-8.259-.702-.807-1.457-1.602-2.375-2.165-.552-.213-1.171-.098-1.75-.157-.273-.005-.549-.022-.821-.02z"
      />
    </g>
  </svg>
)

export default Icon
