let projects = {
    "TopDownShooter": {
        "href": "/p/tds/",
        "commit_client": "https://api.github.com/repos/Kowagatte/TDClient/commits",
        "commit_server": "https://api.github.com/repos/Kowagatte/TDServer/commits"
    },
    // "Raymond": {
    //     "href": "/p/raymond/",
    //     "commit_api": "https://api.github.com/repos/Kowagatte/Raymond/commits"
    // }
}

export {projects};